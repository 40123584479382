import React from "react";

export const ModalContact = ({content}) => {
    let hasLinks = content.phone || content.email || content.links

    let phone = content.phone
        ? content.phone.trim()
        : null

    // TODO: Find a better way to normalise phone numbers
    phone = (null !== phone && parseInt(phone.length) === 10)
        ? '+33' + phone.substring(1)
        : phone

    return (
        <div className="relative w-full p-4 lg:p-8 bg-primary border-l-10 lg:border-l-20 border-secondary text-white">
            <h2 className="title h2 mb-6 uppercase text-white">{content.title}</h2>

            {content.contactName && (
                <div className="contact-name mb-4 font-important">
                    <strong>Contact&nbsp;:</strong>&nbsp;
                    {content.contactName}
                </div>
            )}
            

            {content.address && (
                <div className="contact-address mb-4">
                    <span className="content-inverse text-white" dangerouslySetInnerHTML={{__html: content.address.value}}/>
                </div>
            )}

            {content.body && (
                <div className="contact-body mb-6">
                    <span className="content-inverse text-white" dangerouslySetInnerHTML={{__html: content.body.value}}/>
                </div>
            )}

            {hasLinks && (
                <div className="flex-initial">
                    <div className="buttons flex flex-col md:flex-row flex-wrap space-y-4 md:space-x-4 md:space-y-0">
                        {phone && (
                            <a
                                href={`tel:${phone}`}
                                className="button-sm border-secondary bg-secondary text-white whitespace-nowrap" 
                                title="Téléphone"
                            >
                                <i className="fas fa-phone mr-2" role="presentation"></i>
                                Téléphone
                            </a>
                        )}
                        {content.email && (
                            <a
                                href={`mailto:${content.email}`}
                                className="button-sm border-secondary bg-secondary text-white whitespace-nowrap" 
                                title="Email"
                            >
                                <i className="fas fa-envelope mr-2" role="presentation"></i>
                                Email
                            </a>
                        )}
                        {content.links.map(({uri, title}, index) => (
                            <a
                                key={index}
                                href={uri}
                                className="button-sm border-secondary bg-secondary text-white whitespace-nowrap" 
                                title={title}
                            >
                                <i className="fas fa-external-link-square-alt mr-2" role="presentation"></i>
                                {title}
                            </a>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}