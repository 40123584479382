import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Pagination from "../components/Pagination";
import ContactListItem from "../components/ListItems/ContactListItem";
import Breadcrumb from "../components/Breadcrumb";

const ContactList = ({pageContext, data, location}) => {
    const {
        currentPage,
        numPages,
    } = pageContext

    const pageTitle = pageContext.title ? pageContext.title : "Annuaire";

    return (
        <Layout>
            <Metas title={pageTitle}/>
            <section className="section page-content">
                <div className="container mx-auto px-4">
                    <Breadcrumb/>

                    <h1 className="title h1 text-primary" data-typesense-field="title">{pageTitle}</h1>

                    <div className="columns grid grid-cols-1 xl:grid-cols-2 gap-8">
                        {data.contacts.nodes.map((node, index) => (
                            <div key={index} className="column contact">
                                <ContactListItem content={node}/>
                            </div>
                        ))}
                    </div>

                    <Pagination
                        path={location.pathname}
                        currentPage={currentPage}
                        numPages={numPages}
                    />
                </div>
            </section>
        </Layout>
    )
}

export default ContactList

export const query = graphql`query ($internalId: Int!) {
  contacts: allNodeContact(
    filter: {
      status: {eq: true},
      field_type: {drupal_internal__target_id: {eq: $internalId}}
    }
    sort: {fields: created, order: DESC}
  ) {
    nodes {
      id
      title
      contactName: field_contact
      address: field_adresse {
        value
      }
      body {
        value
      }
      links: field_links {
        uri
        title
      }
      phone: field_telephone
      email: field_email
      relationships {
        image: field_image {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 256, quality: 100, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`
