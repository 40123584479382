import React, { useContext } from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import {ModalContext} from "../../contexts/ModalContext";
import { ModalContact } from "../Modal/ModalContact";
import HtmlParser from "../HtmlParser";

const ContactListItem = ({content}) => {
    const {setModalVisibility, setModalContent} = useContext(ModalContext)

    const clickHandler = () => {
        setModalContent(
            <ModalContact content={content}/>
        )

        setModalVisibility(true)
    }

    return (
        <div className="contact-list-item card h-full flex flex-col md:flex-row">
            <div className="card-image flex-initial flex items-center justify-center h-64 md:h-full min-w-64 border-b-10 border-secondary bg-gray-200 overflow-hidden">
                <div className="w-64">
                    <div className="aspect-w-1 aspect-h-1">
                        <div className="flex items-center justify-center">
                            {content.relationships.image ? (
                                <GatsbyImage
                                    image={content.relationships.image.localFile.childImageSharp.gatsbyImageData}
                                    alt={content.title}
                                />
                            ) : (
                                <i className="fas fa-image text-gray-400 text-8xl"><span className="hidden">Image fallback</span></i>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-content flex-1 p-6 flex flex-col bg-primary text-white">
                <div className="flex-1 mb-4">
                    <div className="relative mb-4 pb-4 before-underline before-bg-secondary">
                        <div className="h3 mb-2 uppercase">{content.title}</div>

                        {content.contactName && (
                            <div className="h4 font-important font-semibold">{content.contactName}</div>
                        )}
                    </div>

                    {content.address ? (
                        <div className="contact-address">
                            <div className="content-inverse">
                                <HtmlParser html={content.address.value}/>
                            </div>
                        </div>
                    ) : content.body && (
                        <div className="contact-body">
                            <div className="content-inverse">
                                <HtmlParser html={content.body.value}/>
                            </div>
                        </div>   
                    )}
                </div>

                <div className="more-link">
                    <button 
                        className="button border-secondary bg-secondary text-white group" 
                        onClick={() => clickHandler()}
                    >
                        En savoir plus
                        <i className="fas fa-arrow-right ml-2"></i>
                    </button>
                </div>                
            </div>
        </div>
    );
}

ContactListItem.propTypes = {
    content: PropTypes.object.isRequired,
}

export default ContactListItem;
